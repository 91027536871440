import { Injectable } from '@angular/core';
import { CreateLimit, Limit, LimitFieldValue, UpdateLimit } from '@type/limits';
import { isStrategyLimitItem } from '@type/strategy.type';
import { deepCopyObj } from '@utils/copy-obj';

@Injectable()
export class StrategyLimitStateService {
  #limit: Limit = new Limit();

  #limitCopyMap = new Map<number, Limit>();

  resetLimit = () => {
    this.#limit = new Limit();
  };

  resetState = () => {
    this.resetLimit();
    this.#limitCopyMap.clear();
  };

  setLimit(limit: Limit) {
    this.#limit = limit;
    !this.#limitCopyMap.has(limit.id) && this.#limitCopyMap.set(limit.id, deepCopyObj(limit));
  }

  getCurrentLimit() {
    return this.#limit;
  }

  getCurrentLimitCopy(limitId: number) {
    return this.#limitCopyMap.get(limitId);
  }

  private toFieldValueAttributes = (slfv: LimitFieldValue) => ({
    ...slfv,
    strategy_field_value_ids: slfv.strategy_field_values.map(({ id }) => id),
  });

  prepareLimitsToBackend(limitList: Limit[]) {
    return limitList.map((limit) =>
      !!limit.id ? this.convertToUpdateLimit(limit).strategy_limit : this.convertToCreateLimit(limit).strategy_limit,
    );
  }

  private convertToCreateLimit = (limit: Limit): CreateLimit => ({
    strategy_limit: {
      operator: limit.operator,
      value: limit.value,
      strategy_limit_field_values_attributes: limit.strategy_limit_field_values.map(this.toFieldValueAttributes),
      strategy_limit_items_attributes: limit.strategy_limit_items.map(({ item_id }) => ({ item_id })),
    },
  });

  private convertToUpdateLimit = (limit: Limit): UpdateLimit => ({
    strategy_limit: {
      id: limit.id,
      operator: limit.operator,
      value: limit.value,
      ...this.getUpdateLimitAttributes(limit),
    },
  });

  private getUpdateLimitAttributes = (limit: Limit) => {
    const copiedItems = this.getCurrentLimitCopy(limit.id)?.strategy_limit_items || [];
    const destroyedItems = this.getDestroyedModel(copiedItems, limit.strategy_limit_items);
    const addedItems = this.getAddedModel(limit.strategy_limit_items, copiedItems);

    return {
      strategy_limit_items_attributes: [
        ...Array.from(addedItems.values()).map((item) =>
          isStrategyLimitItem(item) ? { item_id: item.item_id, id: item.id } : { item_id: item.item_id },
        ),
        ...destroyedItems.map(({ item_id, id }) => ({ item_id, id, _destroy: true })),
      ],
      strategy_limit_field_values_attributes: limit.strategy_limit_field_values.map(this.toFieldValueAttributes),
    };
  };

  private getDestroyedModel(target: { item_id: number; id: number }[], compared: { item_id: number; id: number }[]) {
    return target.filter(({ item_id }) => !compared.find((el) => el.item_id === item_id));
  }

  private getAddedModel(target: { item_id: number; id: number }[], compared: { item_id: number; id: number }[]) {
    return new Map(
      Object.entries(
        target
          .filter(({ item_id }) => item_id === 0 || !compared.find((el) => el.item_id === item_id))
          .reduce(
            (a, c) => {
              a[c.item_id] = c;
              return a;
            },
            {} as Record<number, any>,
          ),
      ),
    );
  }
}
