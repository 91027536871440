import { Operator } from '@enums/operator';
import { FilterType } from '@modules/filter/types/filter-options';
import { StrategyFieldValue, StrategyLimitItem } from './strategy.type';
import { deepCopyObj } from '@utils/copy-obj';

export class Limit {
  id: number = 0;
  operator: Operator = Operator['='];
  value: number = 0;
  strategy_limit_field_values: LimitFieldValue[] = [];
  strategy_limit_items: StrategyLimitItem[] = [];
  uid?: string = crypto.randomUUID();

  constructor(args?: Partial<Limit>) {
    args && Object.assign(this, deepCopyObj(args));
  }
}

export type LimitRequestParams = {
  sId: number | string;
  cId: number | string;
  lId: number | string;
  q?: object;
};

export type ApplyLimitRequestParams = {
  sId: number | string;
  cId: number | string;
  lId: 'bulk_upsert';
};

export type CreateLimit = {
  strategy_limit: {
    id?: number;
    operator: Operator;
    value: number;
    strategy_limit_field_values_attributes: {
      operator: Operator;
      strategy_field_id: ID;
      strategy_field_value_ids: ID[];
    }[];
    strategy_limit_items_attributes: {
      item_id: ID;
    }[];
  };
};

type ID = string | number | null;

export type ApplyLimits = {
  strategy_limits: (UpdateLimit['strategy_limit'] | CreateLimit['strategy_limit'])[];
};

export type UpdateLimit = {
  strategy_limit: {
    id: number;
    operator: Operator;
    value: number;
    strategy_limit_field_values_attributes: {
      id: ID;
      operator: Operator;
      strategy_field_id: ID;
      strategy_field_value_ids: ID[];
    }[];
    strategy_limit_items_attributes: ({ item_id: ID } | { id: ID; _destroy: boolean })[];
  };
};

export type LimitFieldValue = {
  id: number | null;
  operator: Operator;
  strategy_field_id: number;
  field_name: string;
  field_type: FilterType;
  field_localized_name: string;
  strategy_field_values: StrategyFieldValue[];
};

export type LimitDeviation = {
  branch: number[];
  id: number;
  item_id: number;
  strategy_limit_id: number;
  strategy_limit_operator: Operator;
  strategy_limit_value: number;
  value: number;
};

export type LimitList = {
  strategy_limits: Limit[];
};

export type LimitItem = LimitList['strategy_limits'][0]['strategy_limit_field_values'][0];
